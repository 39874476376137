import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import BreadcrumbSection from '../../components/breadcrumb'

const StatusUpdates = ({ location }) => {
     const canonical = process.env.GATSBY_SITE_URL + location.pathname

     const pageName = 'Status'
     const breadcrumbItems = [
          {
               name: `${pageName}`,
          },
     ]

     return (
          <Layout>
               <SEO classBody="status-update" canonical={canonical} />
               <BreadcrumbSection breadcrumbItems={breadcrumbItems}></BreadcrumbSection>
               <section className="platform-intro">
                    <div className="container">
                         <div className="platform-intro-title">
                              <h1>System Status & Updates</h1>
                              <h5>Critical System Status Notifications and Feature Updates Are Shared Here</h5>
                              <p>
                                   Please contact us with any questions at{' '}
                                   <a href="mailto:support@listenlayer.com" target="_blank" rel="noreferrer">
                                        support@listenlayer.com
                                   </a>{' '}
                                   and we'll respond to you inquiry.
                              </p>
                         </div>
                         <div className="platform-intro-content">
                              <h3>System Status</h3>
                              <p>Available. No known system issues exist.</p>
                              <h3>Major Releases</h3>
                              <p>
                                   The following provides a list of recently released and updated features that are considered material enough that
                                   they may impact how you use the platform.
                              </p>

                              <ol>
                                   <li>No releases meet the threshold.</li>
                              </ol>
                         </div>
                    </div>
               </section>
          </Layout>
     )
}

export default StatusUpdates
